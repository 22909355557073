exports.components = {
  "component---src-pages-aktuality-js": () => import("./../../../src/pages/aktuality.js" /* webpackChunkName: "component---src-pages-aktuality-js" */),
  "component---src-pages-cenik-js": () => import("./../../../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kamna-js": () => import("./../../../src/pages/kamna.js" /* webpackChunkName: "component---src-pages-kamna-js" */),
  "component---src-pages-kombinovana-topidla-js": () => import("./../../../src/pages/kombinovana_topidla.js" /* webpackChunkName: "component---src-pages-kombinovana-topidla-js" */),
  "component---src-pages-kominy-js": () => import("./../../../src/pages/kominy.js" /* webpackChunkName: "component---src-pages-kominy-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-krby-js": () => import("./../../../src/pages/krby.js" /* webpackChunkName: "component---src-pages-krby-js" */),
  "component---src-pages-nabizime-js": () => import("./../../../src/pages/nabizime.js" /* webpackChunkName: "component---src-pages-nabizime-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-poradna-js": () => import("./../../../src/pages/poradna.js" /* webpackChunkName: "component---src-pages-poradna-js" */),
  "component---src-pages-speciality-js": () => import("./../../../src/pages/speciality.js" /* webpackChunkName: "component---src-pages-speciality-js" */),
  "component---src-pages-sporaky-js": () => import("./../../../src/pages/sporaky.js" /* webpackChunkName: "component---src-pages-sporaky-js" */),
  "component---src-pages-zasady-ochrany-osobnich-udaju-js": () => import("./../../../src/pages/zasady-ochrany-osobnich-udaju.js" /* webpackChunkName: "component---src-pages-zasady-ochrany-osobnich-udaju-js" */)
}

